import "./App.css";
import "./styles/main.css";
import { useEffect, useState } from "react";
import { shortenAddress } from "./commons/utils";
// import { useWeb3React } from "@web3-react/core";
// import { injected } from "./wallet/Connector";
import { useWallet, UseWalletProvider } from "use-wallet";
import ModalConnect from "./components/ModalConnect";
import Web3 from "web3";
import Form from "./components/Form"
import Sign from "./components/Sign"
import axios from "axios";
import DEFAULTS from "./globals";

function App() {
  const [verified, setVerified] = useState();
  const [signature,setSignature] = useState();
  const [loginNonce, setLoginNonce] = useState()
  // const { active, account, library, activate, deactivate } = useWeb3React();
  const wallet = useWallet();

  useEffect(() => {
    if (wallet.account) {
        axios.post(`${DEFAULTS.API}/canvas/login`,{publicKey:wallet.account})
          .then(res=>{
            setLoginNonce(res.data.nonce)
          })
          .catch(err=>{
            console.log(err)
          })
    }
}, [wallet.account]);

  return (
    <div className="App">
      <img
        src="images/nav-bg-1.png"
        loading="lazy"
        height=""
        sizes="(max-width: 3346px) 100vw, 3346px"
        srcset="images/nav-bg-1-p-500.png 500w, images/nav-bg-1-p-800.png 800w, images/nav-bg-1-p-1080.png 1080w, images/nav-bg-1.png 3346w"
        alt=""
        class="image-35"
      ></img>
      <nav class="px-2 sm:px-4 py-4 mb-5">
        <div class="container flex flex-wrap justify-between items-center mx-auto">
          <a href="/" class="brand-2 w-nav-brand">
            <img
              src="images/site-logo-1.png"
              sizes="197.78125px"
              height="45"
              srcset="images/site-logo-1-p-500.png 500w, images/site-logo-1-p-800.png 800w, images/site-logo-1.png 1912w"
              alt=""
              class="image-36"
            />
          </a>
          <div class="flex md:order-2 items-center">
            {!wallet.isConnected() ? (
              // <button
              //   type="button"
              //   class="text-black bg-white ring-4 ring-black hover:bg-pink-300 py-3 rounded-lg px-3 text-center mr-3 md:mr-0"
              //   onClick={() => wallet.connect()}
              // >
              //   Connect
              // </button>
              <ModalConnect />
            ) : (
              <>
                <div class="mr-3">
                  <span class="inline-flex items-center p-1 mr-2 text-lg font-semibold text-green-800 bg-green-300 rounded-full">
                    <svg
                      class="w-3 h-3"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </span>
                  {shortenAddress(wallet.account)}
                </div>
                <button
                  type="button"
                  class="text-black bg-white ring-4 ring-black hover:bg-pink-300 text-lg py-3 rounded-lg px-3 text-center mr-3 md:mr-0"
                  onClick={() => wallet.reset()}
                >
                  Disconnect
                </button>
              </>
            )}
          </div>
        </div>
      </nav>
      {wallet.isConnected() ? (
        verified ? (<div class="container mb-28 mx-auto mt-28 rounded bg-gray-700 ring-4 ring-black text-left">
        <Form signature={signature}/>
      </div>) : (
        <div class="container mx-auto mt-28 rounded bg-gray-700 ring-4 ring-black text-center">
          <Sign setVerified={setVerified} loginNonce={loginNonce} setSignature={setSignature}/>
        </div>
      )
      )
      :
      (
        <div class="container text-center mx-auto mt-28 rounded bg-gray-700 ring-4 ring-black text-left">
          <div class="text-3xl text-white p-6">Please connect your wallet</div>
        </div>
      )}
    </div>
  );
}

// Wrap everything in <UseWalletProvider />
export default function WrappedApp() {
  return (
    <UseWalletProvider
      chainId={1}
      connectors={{
        walletconnect: {
          rpc: {
            1: "https://mainnet.infura.io/v3/2f22752062a046f5805d600586d86fbd",
          },
        },
        walletlink: {
          chainId: 1,
          url: "https://www.inbetweeners.io/",
          appName: "inBetweeners Raffles",
          appLogoUrl: "https://www.inbetweeners.io/images/webclip.png",
        },
      }}
    >
      <App />
    </UseWalletProvider>
  );
}
