import React, { useState } from "react";
import { useWallet } from "use-wallet";
import {recoverPersonalSignature} from "@metamask/eth-sig-util";
import {bufferToHex} from 'ethereumjs-util';

import axios from "axios";
import DEFAULTS from "../globals";

export default function Form({ setVerified, loginNonce, setSignature }) {
  const [error, setError] = useState(false);
  const wallet = useWallet();
  async function sign() {
    console.log(wallet);
    const { ethereum } = wallet;

    console.log("wallet:", wallet.account.toLowerCase());

    // signMsg(msgParams, wallet.account);
    var msg = bufferToHex(new Buffer(loginNonce, "utf8"));
    signMsg(msg, wallet.account);

    // helper
    function signMsg(msgParams, from) {
      // console.log(ethereum.sendAsync)
      ethereum.sendAsync(
        {
          method: "personal_sign",
          params: [msgParams, from],
          from: from,
        },
        function (err, result) {
          console.log("err", err);
          console.log("result", result);
          if (err) return console.error(err);
          if (result.error) {
            return console.error(result.error.message);
          }
          setSignature(result.result);

          //AXIOS POST
          axios.post(`${DEFAULTS.API}/canvas/verify`,{
              publicKey: from,
              signedData: msgParams,
              signature: result.result
          }).then(res=>{
              if(res.status === 200){
                setVerified(true);
              }
          }).catch(err => {
            setError(true)
          })
        }
      );
    }
  }

  return (
    <>
      <div class="text-3xl text-white p-6">
        We need to verify you're one of the winners !
      </div>
      <div class="text-xl px-6">
        This only requires off-chain signing (0 gas cost)
      </div>
      {!error ? (
        <button
          type="button"
          class="text-black text-lg bg-white ring-4 ring-black hover:bg-pink-300 py-3 m-6 rounded-lg px-3 text-center mr-3 md:mr-0"
          onClick={sign}
        >
          Verify
        </button>
      ) : (
        <div class="text-xl py-5 text-red-400">
          We are unable to verify your wallet, please make sure you are one of
          the winners
        </div>
      )}
    </>
  );
}
